import React, { Component } from "react";
import handshake from '../img/handshake.svg';
import blog from '../img/blog.svg';
import projects from '../img/projects.svg';
import socials from '../img/find-me.svg';

function Nav() {
    return (
        <div className="nav">
            <div className="nav__border">

                <div className="nav__border__option" data-tooltip="Jeszcze nic o sobie nie napisałem 🙃">
                    <a href="#">
                        <img src={handshake} />
                        <span>JA</span>
                    </a>
                </div>
            </div>
            <div className="nav__border">
                <div className="nav__border__option" data-tooltip="Przyjdź innym razem zobaczyć co zmajstrowałem 💪">
                    <a href="#">
                        <img src={projects} />
                        <span>MOJE PROJEKTY</span>
                    </a>
                </div>
            </div>
            <div className="nav__border">
                <div className="nav__border__option" data-tooltip="A tutaj będzie blog 😎">
                    <a href="#">
                        <img src={blog} />
                        <span>BLOG</span>
                    </a>
                </div>
            </div><div className="nav__border">
                <div className="nav__border__option" data-tooltip="Jeżeli tu jesteś to znaczy, że serio chcesz mnie wystalkować. Szukaj dalej!">
                    <a href="#">
                        <img src={socials} />
                        <span>ZNAJDŹ MNIE</span>
                    </a>
                </div>
            </div>
        </div>
    )
}
export default Nav;