import { Helmet } from "react-helmet";
import './App.css';
import './scss/main.scss';
import Hero from './components/Hero';
import Nav from './components/Nav';

function App() {
  return (
      <div className="container">
      <Helmet>
        <title>Czarnecki Michał | Me, myself and I</title>
      </Helmet>
        <div className="main">
          <Hero />
          <Nav />
        </div>
      </div>
  );
}

export default App;
