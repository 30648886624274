import React, {Component} from 'react';
import me from '../img/michalczarnecki-ja.jpg';
import logo from '../img/czarnecki-logo.svg';

function Hero(){
    return (
        <div className="hero">
            <div className="hero__me">
                <img src={ me } />
            </div>
            <div className="hero__logo">
                <img src={ logo } />
            </div>
        </div>
        
    )
}

export default Hero;
